import React from "react";
import TextInput from "../common/TextInput";

/**
 * 여러 입력란이 있는 다이얼로그 (버튼 1개)
 * @param {*} title 다이얼로그 제목
 * @param {*} content 다이얼로그 내용
 * @param {*} input_list input 정보 리스트 (ex. [{ title: "제목1", value: "값1" }, { title: "제목2", value: "값2" }])
 * @param {*} button_text 버튼 텍스트
 * @param {*} infoText input 아래 설명문
 * @param {*} success success 표시(초록 테두리 및 체크표시)
 * @param {*} error error 표시(빨간 테두리 및 느낌표)
 * @function onButtonClick 버튼 클릭 이벤트
 * @function popup_close 팝업 닫기
 */
class InputDialogV3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input_values: props.input_list.map((input) => input.value || ""), // input_list로 초기화
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.input_list) !== JSON.stringify(prevProps.input_list)) {
            this.setState({
                input_values: this.props.input_list.map((input) => input.value || ""),
            });
        }
    }

    onChangeInputValue(index, value) {
        const updatedValues = [...this.state.input_values];
        updatedValues[index] = value;
        this.setState({ input_values: updatedValues }, () => {
            if (this.props.onChange) {
                this.props.onChange(updatedValues);
            }
        });
    }

    render() {
        const { title, content, input_list, button_text, button_color, infoText, success, error, onButtonClick } = this.props;

        return (
            <div className="common_dialog input_dialog_v3">
                <div className="dialog_select_CTA dialog_complete">
                    <div className="dialog_select_CTA_txt">
                        <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
                        <h3 dangerouslySetInnerHTML={{ __html: content }}></h3>
                    </div>
                    <div className="input_list_wrapper">
                        {input_list.map((input, index) => (
                            <div key={index} className="input_wrapper">
                                <b className="input_title text_14">{input.title}</b>
                                <TextInput
                                    value={this.state.input_values[index]}
                                    onChange={(e) => this.onChangeInputValue(index, e.target.value)}
                                    placeholder={input.placeholder || ""}
                                    infoText={infoText}
                                    success={success}
                                    error={error}
                                    width={"270px"}
                                    height={"40px"}
                                    fontSize={"14px"}
                                    autoFocus={index === 0}
                                />
                            </div>
                        ))}
                    </div>
                    <button
                        id="common_alert_dialog_btn"
                        onClick={() => onButtonClick(this.state.input_values)}
                    >
                        {button_text}
                    </button>
                </div>
            </div>
        );
    }
}

export default InputDialogV3;

InputDialogV3.defaultProps = {
    title: "",
    content: "",
    input_list: [], // [{ title: "Input 1", value: "Value 1" }, ...]
    button_text: "확인",
    popup_close: () => {},
    onChange: () => {},
    onButtonClick: () => {},
};