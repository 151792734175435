import React from 'react';
import axios from 'axios';
import http from './modules/api';
import InputDialogV3 from "./modules/dialogs/InputDialogV3";
import * as StringUtil from './modules/utils/StringUtil';

export class Cafe24AutoLoginContact extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			/* url 파라미터 */
			service_type: StringUtil.getUrlParams("service_type"),
			// step: StringUtil.getUrlParams("step"),
			// solution_type_cd: StringUtil.getUrlParams("solution_type_cd"),
			shop_no: StringUtil.getUrlParams("shop_no"),
			isActiveService: StringUtil.getUrlParams("isActiveService"),

			service_name: "",
			service_name_list: [
				{service_type: "01001", service_name: "AI배너"},
				{service_type: "01002", service_name: "상품자동진열"},
				{service_type: "01005", service_name: "트랜드 인사이트"},
				{service_type: "01007", service_name: "큐레이션"},
				{service_type: "01009", service_name: "타겟뷰"},
				{service_type: "01010", service_name: "랭킹 인사이트"},
			],
		}
    }

	componentDidMount() {
		const {service_name_list, service_type} = this.state;
		let service_name = "";
		for (let idx in service_name_list) {
			if (service_name_list[idx]["service_type"] == service_type) {
				service_name = service_name_list[idx]["service_name"];
				break;
			}
		}
		this.setState({
			service_name: service_name,
		})
	}

	onClickBtnCirform(input_values) {
		if (!input_values[0]) {
			alert("담당자명을 입력해 주세요.");
			return false;
		}
		if (!input_values[1]) {
			alert("이메일을 입력해 주세요.");
			return false;
		}
		if (!input_values[2]) {
			alert("연락처를 입력해 주세요.");
			return false;
		}

		const {service_type, shop_no, isActiveService} = this.state;

		const body = {
			contact_user_name: input_values[0],
			contact_email: input_values[1],
			contact_tel_no: input_values[2]
		}
		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.post("/rest/shop/updateUserContact", body , config)
		.then(res => {
			console.log("연락처 등록 완료!!!");

			if (isActiveService == null) {
				if (service_type == "01001" || service_type == "01002") {
					window.location.href = "/shop/serviceShopAdd?service_type="+service_type+"&step=2&solution_type_cd=09001&shop_no="+shop_no;
				} else {
					window.location.href = "/";
				}
			} else {
				if (isActiveService == "true") {
					window.location.href = "/";
				} else {
					window.location.href = "/shop/serviceShopAdd?service_type="+service_type+"&step=1"
				}
			}
		}).catch(error => {
			console.log("연락처 등록 실패!!!");
			console.log(error);

			/* 등록 실패 해도 리다이렉트 */
			if (isActiveService == null) {
				if (service_type == "01001" || service_type == "01002") {
					window.location.href = "/shop/serviceShopAdd?service_type="+service_type+"&step=2&solution_type_cd=09001&shop_no="+shop_no;
				} else {
					window.location.href = "/";
				}
			} else {
				if (isActiveService == "true") {
					window.location.href = "/";
				} else {
					window.location.href = "/shop/serviceShopAdd?service_type="+service_type+"&step=1"
				}
			}
		});
	}

	render() {
		return (
				<>
					<InputDialogV3
						title={"로보MD " + this.state.service_name + "<br/>가입을 환영합니다"}
						input_list={[
							{ title: "담당자명", value: "", placeholder: "안내를 받으실 담당자명을 입력해 주세요." },
							{ title: "이메일", value: "", placeholder: "안내를 받으실 담당자 이메일 주소를 입력해주세요." },
							{ title: "연락처", value: "", placeholder: "안내를 받으실 담당자 연락처 주소를 입력해주세요." },
						]}
						button_text="확인"
						onButtonClick={(values) => {
								// console.log("버튼 클릭, 입력값:", values);
								this.onClickBtnCirform(values)
								// this.setState({
								// 	contact_user_name: values[0],
								// 	contact_email: values[1],
								// 	contact_phone_number: values[2],
								// }, () => {
								// 	// 여기서 DB에 저장
								// 	onClickBtnCirform(input_values)
								// })
							}
						}
					/>
				</>
		);
	}
	
}
