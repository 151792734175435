import React, { useState, useEffect, useRef } from 'react';
import http, { subscribeShopDataSyncState } from '../modules/api';
import axios from 'axios';

// 스타일
import styles from './RpMain.module.css';
import * as StyleUtil from '../modules/utils/StyleUtil';

// 컴포넌트
import Button from '../modules/common/Button';
import RpMainGrid from './component/RpMainGrid';
import TitleContentDialogV2 from '../modules/dialogs/TitleContentDialogV2';
import InputDialogV3 from "../modules/dialogs/InputDialogV3";
import BasicDialogV2 from '../modules/dialogs/BasicDialogV2';
import { set } from 'date-fns';

import ReactGA, { send } from 'react-ga';
ReactGA.initialize('UA-78553461-8', {
    titleCase: false,
});

let window_scrollTop; // 스크롤 위치

export default function RpMain(props) {
    /**
     * states
     * 
     * name:                            string   내 정보 - 이름
     * isFinish:                        boolean  내 정보 - 이용기간 만료 여부
     * remainingDay:                    number   내 정보 - 남은 이용기간
     * endDateFormat:                   String   내 정보 - 이용 종료일
     * syncState                        boolean  내 데이터 정보 - 연동중 유무
     * syncDateFormat:                  String   내 데이터 정보 - 연동일자
     * installCntFormat:                String   내 진열 정보 - 연결된 진열 코너들
     * totalEventSettingCntFormat:      String   내 진열 정보 - 총 상세 상품설정 기준
     * autoProductCntFormat:            String   내 진열 정보 - 진열 변경 주기에 맞춰서 교체된 상품
     * exceptSettingCntFormat:          String   내 진열 정보 - 진열 간 중복 제외 진열 목록
     */
    const [showBasicDialogState, setShowBasicDialogState] = useState(false);
    const [basicDialogContent, setBasicDialogContent] = useState(false);
    const [showFreeTrialExtenssionDialogState, setShowFreeTrialExtenssionDialogState] = useState(false);
    const [tutorialDialogState, setTutorialDialogState] = useState(false);
    const [name, setName] = useState(sessionStorage.getItem("userName"));
    const [isFinish, setIsFinish] = useState(true);
    const [paymentYn, setPaymentYn] = useState(false);
    const [paymentClsText, setPaymentClsText] = useState("");
    const [remainingDay, setRemainingDay] = useState(0);
    const [endDayOfWeek, setEndDayOfWeek] = useState("");
    const [endDateFormat, setEndDateFormat] = useState("");
    const [startDayOfWeek, setStartDayOfWeek] = useState("");
    const [startDateFormat, setStartDateFormat] = useState("");
    const [syncState, setSyncState] = useState("");
    const [syncDateFormat, setSyncDateFormat] = useState("");
    const [installCnt, setIntallCnt] = useState(0);
    const [installCntFormat, setIntallCntFormat] = useState("0");
    const [totalEventSettingCnt, setTotalEventSettingCnt] = useState(0);
    const [totalEventSettingCntFormat, setTotalEventSettingCntFormat] = useState("0");
    const [manualJsonCnt, setManualJsonCnt] = useState(0);
    const [manualJsonCntFormat, setManualJsonCntFormat] = useState("0");
    const [autoProductCnt, setLAutoProductCnt] = useState(0);
    const [autoProductCntFormat, setLAutoProductCntFormat] = useState("0");
    const [exceptSettingCnt, setExceptSettingCnt] = useState(0);
    const [exceptSettingCntFormat, setExceptSettingCntFormat] = useState("0");

    /** 타이틀 컨텐츠 다이얼로그 state */
    const [showSaveTitleContentDialog, setShowSaveTitleContentDialog] = useState(false);
    const [titleContentDialogTitle, setTitleContentDialogTitle] = useState("");
    const [titleContentDialogContent, setTitleContentDialogContent] = useState("");
    const [titleContentDialogButtonList, setTitleContentDialogButtonList] = useState([{
        title : "확인",
        event_method : () => { alert("확인 버튼을 누르셨습니다.") }
    },{
        title : "취소",
        event_method : () => { this.handleToggle("show_save_title_content_dialog") }
    }]);
    const [titleContentDialogZindex, setTitleContentDialogZindex] = useState("");

    const hasFetchedSyncData = useRef(false);

    useEffect(() => {
        // 솔루션 타입 코드 
        loadFreeDatePeriod(); // 무료 체험 기간 조회
        loadShopSyncStateAndSyncDate(); // 플랫폼 API 연동 상태 조회
        loadRpEventConnectionCnt(); // 쇼핑몰별 연결된 진열 영역 개수 조회
        loadTotalRpEventSettingCnt(); // 쇼핑몰별 연결된 진열 영역 개수 조회
        loadRpEventItemSettingMnualJson(); // 알고리즘 설정 개수 조회
        loadTotalRpAutoEventCnt(); // 쇼핑몰별 총 자동 선정된 상품 개수 조회
        loadExceptEventCnt(); // 진열간 제외 설정 개수

        loadTotalRpEventSettingCnt(); // 총 진열 개수
        return () => {
          console.log('컴포넌트가 언마운트됐습니다.');
        };
      }, []);

      /** cafe24_shops sync_state 1(연동중) 일 경우 sync 호출 */
      useEffect (() => {
            if(syncState === "1" && !hasFetchedSyncData.current) {
                try {
                    hasFetchedSyncData.current = true
                    subscribeShopDataSyncState(new Date().getTime())
                        .then(res => callBackSubscribeShopDataSyncState(res))
                } catch (error) {
                    console.log(error)
                }
            }
      }, [syncState])
    
    // GA 이벤트
    function setGaEvent(action_text) {
        ReactGA.event({
             category: "진열 홈",
             action: action_text,
             label: sessionStorage.getItem("user_id"),
         });
    }  

    /** 무료 체험 기간 조회 */
    const loadFreeDatePeriod = () => {
        http.get("/rest/shop/rp/freeDatePeriod")
		.then(res => {
            // console.log(res.data);
			if (res.data != null && res.data != '') {
                let start_date = res.data["start_date_format"];
                start_date = start_date.replace("년","-");
                start_date = start_date.replace("월","-");
                start_date = start_date.replace("일","");
                start_date = start_date.replace(/ /gi,"");

                let end_date = res.data["end_date_format"];
                // end_date = end_date.replace("년","-");
                // end_date = end_date.replace("월","-");
                // end_date = end_date.replace("일","");
                // end_date = end_date.replace(/ /gi,"");

                let payment_yn = res.data["payment_yn"];
                let payment_cls_text = "";
                if (payment_yn == 'Y') {
                    payment_cls_text = "유료";
                } else {
                    payment_cls_text = "무료";
                }

                setRemainingDay(res.data["remain_date"]);
                setStartDayOfWeek(res.data["start_day_of_week"]);
                setStartDateFormat(start_date);
                setEndDayOfWeek(res.data["end_day_of_week"]);
                setEndDateFormat(end_date);
                setIsFinish(res.data["is_finish"]);
                setPaymentYn(res.data["payment_yn"]);
                setPaymentClsText(payment_cls_text);

                if (res.data["remain_date"] && parseInt(res.data["remain_date"]) <= -8) {
                    // setTitleContentDialogZindex("10001");
                    // setShowSaveTitleContentDialog(true);
                    // setTitleContentDialogTitle("무료체험 연장 신청");
                    // setTitleContentDialogContent("오랜만에 재방문 하셨군요?<br/>무료체험 연장 신청을 하시겠습니까?")
                    // setTitleContentDialogButtonList([
                    //     {
                    //         title:"신청하러 가기",
                    //         event_method: () => {
                    //             window.open("https://docs.google.com/forms/d/e/1FAIpQLSd7svi27m5_3nOV_qLtPwcuP174aAT-ybk66wKRQvW45ZZ5Ww/viewform?usp=sf_link");
                    //             setShowSaveTitleContentDialog(false);
                    //             setTitleContentDialogZindex("");
                    //         }
                    //     },
                    //     {
                    //         title: "아니요",
                    //         event_method: () => {
                    //             //팝업 닫기
                    //             setShowSaveTitleContentDialog(false);
                    //             setTitleContentDialogZindex("");
                    //         }
                    //     },
                    // ])

                    setShowFreeTrialExtenssionDialogState(true);
                    setShowSaveTitleContentDialog(false);
                    setTutorialDialogState(false);
                }
			}
		})
		.catch(error => {
		 });
    }

    /** 플랫폼 API 연동 상태 조회 */
    const loadShopSyncStateAndSyncDate = (is_sync_date_check_and_sync_start) => {
        http.get("/rest/shop/shopSyncStateAndSyncDate")
		.then(res => {
			if (res.data != null && res.data != '') {

                setSyncState(res.data.sync_state);
                setSyncDateFormat(res.data.sync_date_format);
                if (is_sync_date_check_and_sync_start == true) {
                    if (res.data.sync_state == "1") {
                        alert("상품 목록을 불러오는 중입니다. 완료될 때까지 기다려 주세요.\n"+
                                "상품 수에 따라 짧게는 몇 분에서 길게는 몇 시간이 걸릴 수 있습니다.");
                    } else {
                        // 상품 연동
                        setShowSaveTitleContentDialog(true);
                        setTitleContentDialogTitle("상품 목록을 새로 불러오시겠습니까?");
                        setTitleContentDialogContent('<p style="word-break:keep-all;">상품 수에 따라 짧게는 몇 분에서 길게는 몇 시간이 걸릴 수 있습니다.</br>' +
                        "그 동안 다른 기능들은 모두 정상적으로 이용하실 수 있습니다.</p>")
                        setTitleContentDialogButtonList([
                            {
                                title:"네",
                                event_method: () => {
                                    //   this.refreshDatasync const Confirm();
                                    fn_refreshDataSync ();
                                    setShowSaveTitleContentDialog(false);
                                }
                            },
                            {
                                title: "아니요",
                                event_method: () => {
                                    //팝업 닫기
                                    setShowSaveTitleContentDialog(false);
                                }
                            },
                        ])
                    }
                }
			}
		})
		.catch(error => {
		 });
    }

    /** 데이터 동기화 */
    const fn_refreshDataSync  = () => {
        try {
            hasFetchedSyncData.current = true
            if (syncState !== "1") setSyncState("1");

            axios.post('/rest/shop/refreshDataSyncShop', {}, {
                headers: {
                    'Connection' : 'keep-alive',
                    'Keep-Alive' : 'timeout=40000',
                    'X-requested-With' : 'XMLHttpRequest',
                    'Content-type': 'application/json;',
                }
            })
            .then(res => {
                /** 최초 호출시 cafe24API 호출 connection timeout 외 예외 발생시 */
                if (!res || res.data.code !== 200) {
                    if (hasFetchedSyncData.current) {
                        throw new Error('ERR_EMPTY_RESPONSE')
                    }
                } else {
                    /** 200/OK */
                    loadShopSyncStateAndSyncDate()
                }
            })
            .catch(error => {
                /** 서버 타임아웃 */
                subscribeShopDataSyncState(new Date().getTime())
                    .then(res => callBackSubscribeShopDataSyncState(res))
            })   
        } catch (err) {
            console.error(err)
        }
    }

    const callBackSubscribeShopDataSyncState = (res) => {
        hasFetchedSyncData.current = false
        if (res === 0) return
        if (res === true) setSyncState("0")
        else if (typeof res === "string"){
            /** 시간초과 || 서버 메세지*/
            console.log(res)
        } else {
            /** response.data 없을 경우 */
            console.err(res)
        }
    }


    /** 쇼핑몰별 연결된 진열 영역 개수 조회 */
    const loadRpEventConnectionCnt =() => {
        http.get("/rest/shop/rp/event/connection/cnt")
		.then(res => {
			if (res.data != null && res.data != '') {
                let install_cnt = res.data["install_cnt"];
                let install_cnt_format = res.data["install_cnt_format"];

                setIntallCnt(install_cnt);
                setIntallCntFormat(install_cnt_format);
			}
		})
		.catch(error => {
		 });
    }
   
    /** 쇼핑몰별 총 진열 영역 개수 조회 */
    const  loadTotalRpEventSettingCnt = () => {
        http.get("/rest/shop/rp/event/setting/cnt")
		.then(res => {
            // console.log(res.data);
			if (res.data != null && res.data != '') {
                let total_event_cnt = res.data["cnt"];
                let total_event_cnt_format = res.data["cnt_format"];

                setTotalEventSettingCnt(total_event_cnt);
                setTotalEventSettingCntFormat(total_event_cnt_format);

                if(Number(total_event_cnt) === 0){
                    window_scrollTop = StyleUtil.onPopupNoneScroll();
                    if (!showFreeTrialExtenssionDialogState) { // 무료체험 연장 신청 다이얼로그가 떠야 할 경우엔 튜토리얼 팝업을 띄우지 않음.
                        setTutorialDialogState(true);
                    }
                }
			}
		})
		.catch(error => {
		 });
    }
   
    /** 총 알고리즘 설정수 조회 */
    const loadRpEventItemSettingMnualJson = () => {
        http.get("/rest/shop/rp/event/setting/manualJsonCnt")
		.then(res => {
            // console.log(res.data);
			if (res.data != null && res.data != '') {
                let manual_json_cnt = res.data["cnt"];
                let manual_json_cnt_format = res.data["cnt_format"];

                setManualJsonCnt(manual_json_cnt);
                setManualJsonCntFormat(manual_json_cnt_format);
			}
		})
		.catch(error => {
		 });
    }
    
    /** 쇼핑몰별 총 자동 선정된 상품 개수 조회 */
    const  loadTotalRpAutoEventCnt = () => {
        http.get("/rest/shop/rp/event/auto/product/cnt")
		.then(res => {
            // console.log(res.data);
			if (res.data != null && res.data != '') {
                let auto_product_cnt = res.data["cnt"];
                let auto_product_cnt_format = res.data["cnt_format"];

                setLAutoProductCnt(auto_product_cnt);
                setLAutoProductCntFormat(auto_product_cnt_format);
			}
		})
		.catch(error => {
		 });
    }
    
    /** 진열간 제외 설정 개수 */
    const  loadExceptEventCnt = () => {
        http.get("/rest/shop/rp/event/except/setting/cnt")
		.then(res => {
            // console.log(res.data);
			if (res.data != null && res.data != '') {
                let except_setting_cnt = res.data["cnt"];
                let except_setting_cnt_format = res.data["cnt_format"];

                setExceptSettingCnt(except_setting_cnt);
                setExceptSettingCntFormat(except_setting_cnt_format);
			}
		})
		.catch(error => {
		 });
    }

    /** "서비스 상담하기" 버튼 클릭 이벤트 */
    const handleServiceConsulting = () => {
        // 문의하기 페이지로 이동
        window.location.href="/shop/qna/form?qna_type_cd=2109"
        return;
    }

    /** "진열 추가하기" 버튼 클릭 이벤트 */
    const handleCreateList = () => {
        if (sessionStorage.getItem("solution_type_cd") == "09001") {
            window.location.href="/shop/rp/event/product/cafe24List"
        } else {
            window.location.href="/shop/rp/event/product/list"
        }
        return;
    }

    // cafe24 메인 진열 등록 페이지로 이동
    function addMainList(e){
        // setGaEvent("메인 진열 등록 클릭");
        props.history.push(
            {
                pathname: sessionStorage.getItem("solution_type_cd") == "09001"
                ? `/shop/rp/event/product/cafe24List/16006`
                : `/shop/rp/event/product/list`
            }
        )
    }

    function onClickFreeTrialExtenssionBtnCirform(input_values) {
        if (!input_values[0]) {
			setBasicDialogContent("담당자명을 입력해 주세요.");
			setShowBasicDialogState(true);
			return false;
		}
		if (!input_values[1]) {
			setBasicDialogContent("이메일을 입력해 주세요.");
            setShowBasicDialogState(true);
			return false;
		}
		if (!input_values[2]) {
            setBasicDialogContent("연락처를 입력해 주세요.");
            setShowBasicDialogState(true);
			return false;
		}

		const body = {
			user_name: input_values[0],
			email: input_values[1],
			tel_no: input_values[2]
		}
		const config = {
            headers: {
                'content-type': 'application/json;'
            }
        }
		http.post("/rest/shop/insertUserFreeTrialExtension", body , config)
		.then(res => {
			console.log("무료체험 연장 신청 완료!!!");
            setShowFreeTrialExtenssionDialogState(false);
		}).catch(error => {
			console.log("무료체험 연장 신청 실패!!!");
			console.log(error);
            setShowFreeTrialExtenssionDialogState(false);
		});
    }

    return (
        <div id="common-page-wrapper">
            <div className={styles.wrap}>
                <h2>진열</h2>
                <div className={styles.section}>
                    <h3>내 정보</h3>
                    <div className={styles.list_wrap}>
                        <div className={styles.list}>
                            <div className={styles.list_name}>
                                {name}
                                {
                                    isFinish
                                    ?   <div className={styles.list_use_finish}>종료</div>
                                    :   <div className={styles.list_trial}>{paymentClsText}</div>
                                }
                            </div>
                            <div className={styles.list_divider}></div>
                            <div className={styles.list_content}>
                                <h4>기간</h4>
                                <div>
                                    {
                                        Number(remainingDay) < 0
                                        ? (Number(remainingDay)*-1)+"일 지남"
                                        : remainingDay+"일 남음"
                                    }    
                                </div>
                            </div>
                            <div className={styles.list_divider}></div>
                            <div className={styles.list_content}>
                                <h4>종료일</h4>
                                <div>{endDateFormat}</div>
                            </div>
                            {
                                paymentYn != "Y"
                                ?   <Button 
                                        onClick={() => {handleServiceConsulting()}}
                                        type={"hover_mint"} 
                                        width={"112px"} 
                                        height={"30px"} 
                                        fontSize={"14px"}
                                    >
                                        서비스 상담신청
                                    </Button>
                                :   ""
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.section}>
                    <h3>내 데이터 정보</h3>
                    <div className={styles.list_wrap}>
                        <div className={styles.list}>
                            <div className={styles.list_name}>쇼핑몰에 등록된 최신상품 연동 일자</div>
                            <div className={styles.list_content}>
                                <h4>업데이트일</h4>
                                <div>{syncDateFormat}</div>
                            </div>
                            <div className={styles.list_content}>
                                <h4>평균주기</h4>
                                <div>{"매일 새벽 2시"}</div>
                            </div>
                            {
                                syncState == "1"
                                ?   <Button onClick={()=>{
                                            loadShopSyncStateAndSyncDate(true)
                                        }}
                                        type={"hover_mint"}
                                            width={"128px"}
                                            height={"30px"}
                                            fontSize={"14px"}>
                                            <div className='flexBox-no-wrap flexBox-align-center-center'>
                                                연동중
                                                <div className="list_loading" style={{marginLeft: "5px"}}>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </div>
                                    </Button> 
                                :   syncState == "0"
                                    ?   <Button 
                                            onClick={() => {loadShopSyncStateAndSyncDate(true)}}
                                            type={"hover_mint"}
                                            width={"128px"}
                                            height={"30px"}
                                            fontSize={"14px"}
                                        >
                                            데이터 동기화
                                        </Button>
                                    :   ""    
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.section}>
                    <h3>내 진열 정보</h3>
                    <div className={styles.list_wrap}>
                        {/* <div className={styles.list}> */}
                        <div className={styles.list_align_start}>
                            <div className={styles.list_name}>연결된 진열 코너들</div>
                            <div className={styles.list_content}>
                                <h4>개수</h4>
                                <div>{installCntFormat}개</div>
                            </div>
                            <div className={styles.list_content}>
                                <h4>총 알고리즘 설정 수</h4>
                                <div>{manualJsonCntFormat}건</div>
                            </div>
                            {/* <Button 
                                onClick={() => {handleCreateList()}}
                                type={"hover_mint"}
                                width={"112px"}
                                height={"30px"}
                                fontSize={"14px"}
                            >
                                진열 추가하기
                            </Button> */}
                        </div>
                        <div className={styles.list_align_start}>
                            <div className={styles.list_name}>진열 변경 주기에 맞춰서 교체된 상품</div>
                            <div className={styles.list_content}>
                                <h4>총 개수</h4>
                                <div>{autoProductCntFormat}개</div>
                            </div>
                            {/* <div className={styles.list_content}>
                                <h4>금일 변경 상품 중 매출 발생액</h4>
                                <div>{listProfited}개</div>
                            </div> */}
                        </div>
                        <div className={styles.list_align_start}>
                            <div className={styles.list_name}>진열 간 중복 제외 진열 목록</div>
                            <div className={styles.list_content}>
                                <h4>총 개수</h4>
                                <div>{exceptSettingCntFormat}개</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.grid_section}>
                    <h3>서비스 기능 소개</h3>
                    <div className={styles.grid_wrap}>
                        <div className={styles.grid}>
                            <RpMainGrid
                                type="connect"
                                linkHref="/shop/rp/event/product/cafe24List"
                                imgSrc="/img/rollingProduct/connect.svg"
                                imgAlt="진열 연결 아이콘"
                                title="진열 연결"
                                content="
                                    내 쇼핑몰 진열 목록과 미리보기로 연결합니다. 
                                    쇼핑몰 적용을 누르기 전에는 내 쇼핑몰에 직접 
                                    반영되지는 않습니다.
                                "
                            />
                        </div>
                        <div className={styles.grid}>
                            <RpMainGrid
                                type="keyword"
                                linkHref="/shop/rp/event/product/cafe24List"
                                imgSrc="/img/rollingProduct/search.svg"
                                imgAlt="키워드 별 테마 기획전 아이콘"
                                title="키워드 별 테마 기획전"
                                content="
                                    상품속성의 키워드로 여러 카테고리별 상품을 한번에 담고 자동등록되는 테마별 기획상품전시를 손쉽게 하실 수 있습니다.
                                "
                            />
                        </div>
                        <div className={styles.grid}>
                            <RpMainGrid
                                type="period"
                                linkHref="/shop/rp/event/product/cafe24List"
                                imgSrc="/img/rollingProduct/calender.svg"
                                imgAlt="상품 자동 진열 주기 아이콘"
                                title="상품 자동 진열 주기"
                                content="
                                    매번 바뀌는 신상품과 소비자 활동 데이터를 통계로 상품진열을 자동교체하는 주기를 일자와 시간으로 선택하실 수 있습니다.
                                "
                            />
                        </div>
                        <div className={styles.grid}>
                            <RpMainGrid
                                type="ranking"
                                linkHref="/shop/rp/event/product/cafe24List"
                                imgSrc="/img/rollingProduct/ranking_page.svg"
                                imgAlt="랭킹안의 랭킹 알고리즘 아이콘"
                                title="랭킹안의 랭킹 알고리즘"
                                content="
                                    하나의 상품진열랭킹안에서 상품 하나하나마다 개별 랭킹알고리즘 적용과 카테고리, 등록일, 분석일을 다양하게 설정하여 사람이 하듯이 정교하게 진열하실 수 있습니다.
                                "
                            />
                        </div>
                        <div className={styles.grid}>
                            <RpMainGrid
                                type="design"
                                linkHref="/shop/rp/event/product/list"
                                imgSrc="/img/rollingProduct/menu.svg"
                                imgAlt="디자인 진열 위젯 아이콘"
                                title="디자인 진열 위젯"
                                content="
                                    새로운 디자인유형으로 진열위젯을 추가로 설치해서 원하는 페이지와 원하는 위치에 자동진열을 추가하실 수 있습니다.
                                "
                            />
                        </div>
                        <div className={styles.grid}>
                            <RpMainGrid
                                type="random"
                                linkHref="/shop/rp/event/product/list"
                                imgSrc="/img/rollingProduct/note.svg"
                                imgAlt="랜덤 상세 추천 위젯 아이콘"
                                title="랜덤 상세 추천 위젯"
                                content="
                                    상품 상세페이지에 랜덤으로 변경되는 인기상품을 위젯으로 추가하여 다양한 상품을 추천하실 수 있습니다.
                                "
                            />
                        </div>
                        <div className={styles.grid}>
                            <RpMainGrid
                                type="except"
                                linkHref="/shop/rp/event/product/cafe24List"
                                imgSrc="/img/rollingProduct/exclude.svg"
                                imgAlt="중복 진열 상품 제외 아이콘"
                                title="중복 진열 상품 제외 위젯"
                                content="
                                    자동으로 추천되는 인기상품이 중복되지 않도록 자동진열상품끼리 중복제외 설정을 해서 많은 인기상품을 추천하실 수 있습니다.
                                "
                            />
                        </div>
                        <div className={styles.grid}>
                            <RpMainGrid
                                type="add"
                                linkHref="/shop/rp/event/product/cafe24List"
                                imgSrc="/img/rollingProduct/statistics.svg"
                                imgAlt="상품 통계 보기 아이콘"
                                title="상품 통계 보기 위젯"
                                content="
                                    상품통계와 상품이미지를 한번에 보면서 상품진열을 손쉽게 미리보면서 변경하실 수 있습니다.
                                "
                            />
                        </div>
                        <div className={styles.grid_empty}></div>
                        <div className={styles.grid_empty}></div>
                        <div className={styles.grid_empty}></div>
                    </div> 
                </div>
            </div>
            {showSaveTitleContentDialog &&
                <TitleContentDialogV2
                    title={titleContentDialogTitle}
                    content={titleContentDialogContent}
                    button_list={titleContentDialogButtonList}
                    popup_close={() => {setShowSaveTitleContentDialog(false);}}
                    zIndex={titleContentDialogZindex}
                />
            }
            {
                tutorialDialogState &&
                    <TitleContentDialogV2
						title={"로보MD 진열 사용하러 가볼까요?"}
						content={"다양한 자동 진열기능을 사용해 보시고<br/>자동 진열되는 상품결과를 미리보고 체험하세요."}
						button_list={[
                            {
                                title: "진열기능보기",
                                type: "1",
                                event_method: () => {
                                    addMainList();
                                    //팝업 닫기
                                    setTutorialDialogState(false);
                                    StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                }
                            },
                            {
                                title: "닫기",
                                type: "0",
                                event_method: () => {
                                    //팝업 닫기
                                    setTutorialDialogState(false);
                                    StyleUtil.clearPopupNoneScroll(window_scrollTop);
                                }
                            }
                        ]}
                        popup_close={() => {
                            setTutorialDialogState(false);
                            StyleUtil.clearPopupNoneScroll(window_scrollTop);
                        }}
					/>
            }
            {
                showFreeTrialExtenssionDialogState &&
                <InputDialogV3
                    title="재방문을 환영합니다"
                    content="<p>
                            잊지않고 찾아주셔서 감사합니다
                            <br/>이전보다 잘 사용하실 수 있도록
                            <br/>전문운영MD가 등록을 도와드릴게요
                            <br/>재방문혜택으로  체험기간도
                            <br/>무료료 연장해드리겠습니다
                            </p>
                            <p>
                            혜택을 안내받으실
                            <br/>담당자연락처를 남겨주세요
                            </p>"
                    input_list={[
                        { title: "담당자명", value: "", placeholder: "진열연동 안내를 받으실 담당자명을  입력해 주세요." },
                        { title: "이메일", value: "", placeholder: "안내 받으실 담당자 이메일 주소를 입력해주세요." },
                        { title: "연락처", value: "", placeholder: "안내 받으실 담당자 연락처를 입력해주세요." },
                    ]}
                    button_text="확인"
                    onButtonClick={(values) => {
                            // console.log("버튼 클릭, 입력값:", values);
                            onClickFreeTrialExtenssionBtnCirform(values);
                        }
                    }
                />
            }
            {
                showBasicDialogState && 
                <BasicDialogV2
                    content={basicDialogContent}
                    button_list={[{
                        title : "확인",
                        event_method : () => { setShowBasicDialogState(false); }
                    }]}
                />
            }            
        </div>
    )
}