import React from 'react';
import axios from 'axios';
import http from '../modules/api';
import '../../css/style.css';

export class AdminUserDetail extends React.Component {
	
	constructor(props) {
		super(props);
	    this.state = {options:[]}
	};
	
	componentDidMount() {
		// uri 파라메터 얻기: this.props.match.params
	    const {user_id} = this.props.match.params;
	    this.getUser(user_id);
	    this.getCode("2200");		//권한코드조회
	};
	
	//공통코드 조회
	async getCode(cd_class) {
	    const res = await http.get("/rest/common/roboMdCode?cd_class="+cd_class);
	    this.setState({options:res.data});
	};
	
	//사용자 상세 조회
	async getUser(id) {
		const res = await http.get(`/rest/admin/userFind/${id}`);
		var corp_addr = res.data.data.corp_addr;

		//기본, 상세주소 자르기
		if (corp_addr != null || corp_addr != '') {
			res.data.data.corp_addr = corp_addr.split('|')[0];
			res.data.data.corp_addr2 = corp_addr.split('|')[1];
		}
	    this.setState(res.data.data);
	};
	
	//권한 변경
	saveRoles(role_cls) {
		const user_id = this.state.user_id;
	    http.post("/rest/admin/adminAuthoritiesModify", null, { 
	    	params:{
	    			 user_id : user_id
	    			,role_cls : role_cls
			}
	    })
		 .then(
				 res => {
					 alert(user_id + "님의 권한변경이 완료되었습니다.");
					 //cafe24 인 경우
					//  if (role_cls == 2204) {
					// 	this.userSyncData(user_id);
					//  }
				 })
		 .catch(
				 error => { alert("권한변경 오류 발생."); }
		 );
	};

	//cafe24경우 데이터 동기화 진행
	userSyncData(user_id) {
		http.post("/rest/admin/adminCafe24UserSyncData", null, { 
	    	params:{
	    			 user_id : user_id
			}
	    })
	}
	
	//변경 이벤트
	handleText = (e, key) => {
	    // computed property
	    this.setState({[key]: e.target.value});
	    //저장
	    this.saveRoles(e.target.value);
	};
	
	//패스워드 초기화
	onInitPw = (e, key) => {
		const user_id = this.state.user_id;
		console.log("user_id : " +  user_id);
		http.post("/rest/admin/adminPwdModify", {user_id:user_id})
			 .then(function (response) {
				 alert(user_id + "님의 패스워드가 '11111'로 초기화 되었습니다.");
			 })
			 .catch(
				 error => { console.log("Data가 없습니다."); }
			 );
	};
	
	// 목록으로 이동 (뒤로가기)
	onGoList = () => {
		this.props.history.goBack();
	};

	// 사용자 전환
	onSwitchUser = () => {
		const user_id = this.state.user_id;
		const token = this.state.token;
		
		if (token == null || token == "") {
			alert("해당 사용자의 토큰이 존재하지 않습니다.");
			return false;
		}

		http.get("/rest/admin/impersonate?username="+user_id, { /*headers : {"X-AUTH-TOKEN" : token}*/ } )
		.then(res => {
			if (res.status == 200) {
				// list, index storage 삭제
				localStorage.clear();

				alert("[" +res.status + "] 사용자 전환 성공");
				sessionStorage.setItem("isLoadBannerInstallCntForMenu", true); // 메뉴용 설치 여부 적용 상태값
				window.location.href = res.request.responseURL;
			} else {
				alert("[" +res.status + "] 사용자 전환 실패");
			}
        })
	};
	
	render() {
		
		return (	
				
			this.state ?
					
			<div id='page-wrapper' className="container user_detail_container" style={{backgroundColor: '#ffffff', minHeight:"100%"}}>
				<div className="form-horizontal">
					<h3>사용자 상세</h3>
					<div className="wrapper wrapper-content animated fadeInRight">
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>사용자 순번</label>
							<div className="col-sm-10">
								<input type="text" id="user_seq" className="form-control" style={{width:"250px"}}
								   value={this.state.user_seq} 
								   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>사용자 ID</label>
							<div className="col-sm-10">
								<input type="text" id="user_id" className="form-control" style={{width:"250px"}}
 									   value={this.state.user_id} 
									   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>사용자 PW</label>
							<div className="col-sm-10">
								<button className="greenButton margin-r-5 password_reset_btn" onClick={this.onInitPw}>패스워드 초기화</button>&nbsp;
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>사업자등록번호</label>
							<div className="col-sm-10">
								<input type="text" id="corp_reg_no" className="form-control" style={{width:"250px"}}
									   value={this.state.corp_reg_no} 
									   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>재직자 수</label>
							<div className="col-sm-10">
								<input type="text" id="emp_cnt" className="form-control" style={{width:"250px"}}
									   value={this.state.emp_cnt} 
									   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>소속명</label>
							<div className="col-sm-10">
								<input type="text" id="corp_name" className="form-control" style={{width:"250px"}}
								   value={this.state.corp_name} 
								   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>담당자명</label>
							<div className="col-sm-10">
								<input type="text" id="user_name" className="form-control" style={{width:"250px"}}
								   value={this.state.user_name} 
								   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>연락처</label>
							<div className="col-sm-10">
								<input type="text" id="tel_no" className="form-control" style={{width:"250px"}}
								   value={this.state.tel_no} 
								   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>이메일</label>
							<div className="col-sm-10">
								<input type="text" id="email" className="form-control" style={{width:"250px"}}
								   value={this.state.email} 
								   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>관리자명</label>
							<div className="col-sm-10">
							{
								this.state.contact_user_name != null
								?    <input type="text" id="contact_user_name" className="form-control" style={{width:"250px"}}
									value={this.state.contact_user_name} 
									readonly="readonly" />
								:	<input type="text" id="manager_name" className="form-control" style={{width:"250px"}}
									value={this.state.manager_name} 
									readonly="readonly" />
                        	}
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>관리자 연락처</label>
							<div className="col-sm-10">
							{
								this.state.contact_tel_no != null
								?    <input type="text" id="contact_tel_no" className="form-control" style={{width:"250px"}}
									value={this.state.contact_tel_no} 
									readonly="readonly" />
								:	<input type="text" id="manager_tel_no" className="form-control" style={{width:"250px"}}
									value={this.state.manager_tel_no} 
									readonly="readonly" />
                        	}
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>관리자 이메일</label>
							<div className="col-sm-10">
							{
								this.state.contact_email != null
								?	<input type="text" id="contact_email" className="form-control" style={{width:"250px"}}
									value={this.state.contact_email} 
									readonly="readonly" />
								:	<input type="text" id="manager_email" className="form-control" style={{width:"250px"}}
									value={this.state.manager_email} 
									readonly="readonly" />
							}
							</div>
						</div>
						<div className="form-group">
							<div>
								<label className="col-sm-2 control-label" style={{float:"left"}}>기본 주소</label>
								<div className="col-sm-10">
									<input type="text" id="corp_addr" className="form-control" style={{width:"250px"}}
										value={this.state.corp_addr} 
										readonly="readonly" />
								</div>
							</div>
							<div className="m-t">
								<label className="col-sm-2 control-label" style={{float:"left"}}>상세 주소</label>
								<div className="col-sm-10">
									<input type="text" id="corp_addr2" className="form-control" style={{width:"250px"}}
										value={this.state.corp_addr2} 
										readonly="readonly" />
								</div>
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>사용자 가입 구분</label>
							<div className="col-sm-10">
								<input type="text" id="user_join_cls" className="form-control" style={{width:"250px"}}
								   value={this.state.user_join_cls} 
								   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>권한 구분</label>
							<div className="col-sm-10">
								<select className="form-control" id="role_cls" style={{width:"250px"}}
			                        value={this.state.role_cls} onChange={(e)=>this.handleText(e, 'role_cls')}>
										{this.state.options.map((option, index) => <option value={option.cd_code}>{option.cd_desc}</option>)}
				                </select>
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>가입일자</label>
							<div className="col-sm-10">
								<input type="text" id="reg_date" className="form-control" style={{width:"250px"}}
								   value={this.state.reg_date} 
								   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>수정일자</label>
							<div className="col-sm-10">
								<input type="text" id="mod_date" className="form-control" style={{width:"250px"}}
								   value={this.state.mod_date} 
								   readonly="readonly" />
							</div>
						</div>
						<div className="form-group">
							<label className="col-sm-2 control-label" style={{float:"left"}}>마지막 로그인 일자</label>
							<div className="col-sm-10">
								<input type="text" id="last_login_date" className="form-control" style={{width:"250px"}}
								   value={this.state.last_login_date} 
								   readonly="readonly" />
							</div>
						</div>
					</div>
				</div>
				<div className="flexBox" style={{alignItems: "center", justifyContent: "center", paddingBottom: "80px"}}>
					<div style={{textAlign:"center"}}>
						<button className="greenButton user_detail_btn" onClick={this.onGoList}>목록</button>
					</div>
					<div id="btnDel" style={{textAlign:"center", marginLeft:"10px", lineHeight:"27px", cursor:"pointer"}}>
						<button className="greenButton user_detail_btn" onClick={this.onSwitchUser}>세션 전환</button>
					</div>
				</div>
		        {/*<button onClick={this.onSave}>저장</button>&nbsp;*/}
			</div>
			: 'non-data'
		);
	}
	
	componentWillReceiveProps(nextProps, nextContext) {
		this.getUser(nextProps.match.params.user_id);
	}
	
}
