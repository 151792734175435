import React from "react";
import CheckBox from "../common/CheckBox";

class TitleContentDialogV2 extends React.Component {

	render () {

		return (
			<>
				{/* 제목 및 내용이 있는 다이얼로그 */}
                <div class="common_dialog" style={{zIndex:this.props.zIndex ? this.props.zIndex : null}}>
                    <div class="dialog_select_CTA">
                        <button class="dialog_close_btn" onClick={(e) => this.props.popup_close()}>
                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.0704 15.1788C16.1878 15.2973 16.2536 15.4573 16.2536 15.6241C16.2536 15.7909 16.1878 15.9509 16.0704 16.0694C15.9509 16.1849 15.7913 16.2495 15.6251 16.2495C15.4589 16.2495 15.2992 16.1849 15.1798 16.0694L10.0001 10.8819L4.82041 16.0694C4.70095 16.1849 4.54127 16.2495 4.3751 16.2495C4.20892 16.2495 4.04925 16.1849 3.92979 16.0694C3.81242 15.9509 3.74658 15.7909 3.74658 15.6241C3.74658 15.4573 3.81242 15.2973 3.92979 15.1788L9.11728 9.99912L3.92979 4.81943C3.83012 4.698 3.77919 4.54383 3.78689 4.38693C3.7946 4.23002 3.8604 4.08159 3.97148 3.9705C4.08256 3.85942 4.231 3.79362 4.3879 3.78592C4.54481 3.77821 4.69898 3.82914 4.82041 3.92881L10.0001 9.11631L15.1798 3.92881C15.3012 3.82914 15.4554 3.77821 15.6123 3.78592C15.7692 3.79362 15.9176 3.85942 16.0287 3.9705C16.1398 4.08159 16.2056 4.23002 16.2133 4.38693C16.221 4.54383 16.1701 4.698 16.0704 4.81943L10.8829 9.99912L16.0704 15.1788Z" fill="#333333"/>
                            </svg>
                        </button>
                        <div class="dialog_select_CTA_txt">
                            <h2 dangerouslySetInnerHTML={{__html: this.props.title}}>
                            </h2>
                            <h3 dangerouslySetInnerHTML={{__html: this.props.content}}>
                            </h3>
                            {(this.props.check_box_text && this.props.check_box_text !== "") && (
                                <>
                                    <CheckBox 
                                        text={this.props.check_box_text}
                                        onChangeCheck={this.props.onChangeCheckBox}
                                        checked={this.props.check_box_checked}
                                    />
                                    <br/>
                                </>     
                            )}
                        </div>
                        <div class="dialog_select_CTA_btn_wrap">
                            <button onClick={this.props.button_list[1].event_method}>{this.props.button_list[1].title}</button>
                            <button id="common_alert_dialog_btn" class="hover_mint_dialog" onClick={this.props.button_list[0].event_method}
                                onKeyDown={e => (e.key=='Enter' ? this.props.button_list[0].event_method : "")} autoFocus={true}>
                                    {this.props.button_list[0].title}
                            </button>
                        </div>
                    </div>
                </div>
			</>
		);
	}
}

export default TitleContentDialogV2;