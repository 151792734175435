import React from 'react';
// import ReactPixel from "react-facebook-pixel";
import {ga4react, reactPixel} from './Menu'

import http from './modules/api';

// ReactPixel.init('438227415050093');
reactPixel.pageView();

/** 
 * contact_user_name, contact_email, contact_tel_no 3가지 값이 있는 경우, manager_name, manager_email, manager_tel_no 대신 나옴.
 * 수정시 contact_ 컬럼이 업데이트됨.
 * contact_ 컬럼은 카페24 자동 회원가입 후 나오는 팝업에서 최초 등록되며, 현재 페이지 (나의 정보 수정) 에서 수정 할 수 있음.
 * */
class SignupForm_trend extends React.Component {

    constructor(props) {
        super(props);
    }

    // 구글 애널리틱스(GA4) 이벤트
    handleManagerNameOnBlur = () => {
        ga4react.event('[회원가입] Step4: 관리자 이름 입력', '관리자 이름 입력 ID=' + this.props.userState.user_id, '회원가입');
        reactPixel.trackCustom('[회원가입] Step4: 관리자 이름 입력', {user_id : this.props.userState.user_id});
    }

    handleManagerEmailOnBlur = () => {
        ga4react.event('[회원가입] Step4: 관리자 이메일 입력', '관리자 이메일 입력 ID=' + this.props.userState.user_id, '회원가입');
        reactPixel.trackCustom('[회원가입] Step4: 관리자 이메일 입력', {user_id : this.props.userState.user_id});
    }

    handleManagerTelNoOnBlur = () => {
        ga4react.event('[회원가입] Step4: 관리자 연락처 입력', '관리자 연락처 입력 ID=' + this.props.userState.user_id, '회원가입');
        reactPixel.trackCustom('[회원가입] Step4: 관리자 연락처 입력', {user_id : this.props.userState.user_id});
    }

    handleCorpAddrOnBlur = () => {
        ga4react.event('[회원가입] Step4: 회사 주소 입력', '회사 주소 입력 ID=' + this.props.userState.user_id, '회원가입');
        reactPixel.trackCustom('[회원가입] Step4: 회사 주소 입력', {user_id : this.props.userState.user_id});
    }


    render () {
        return (
            // this.props.user_join_cls == 'trend' ?
            this.props.user_join_cls == 'cafe24' 
            || this.props.userState.user_id.indexOf("_09001") >= 0 ?
                <>
                    {/* ******************************************트렌드 리포트 가입 신청서****************************************** */}
                    {/* <div className="form-group m-b-md">
                        <label>상호명</label><small className="text-navy"> (*필수입력사항)</small>
                        <div className="input-group">
                            <input type="text" id="corp_name" className="form-control"
                                value={this.props.userState.corp_name} 
                                onChange={(e) => this.props.handleText(e, 'corp_name')} 
                                required maxLength="50" />
                        </div>
                    </div> */}
                    {/* <div className="form-group m-b-md">
                        <label>대표자명</label><small className="text-navy"> (*필수입력사항)</small>
                        <div className="input-group">
                            <input type="text" id="user_name" className="form-control"
                                value={this.props.userState.user_name} 
                                onChange={(e) => this.props.handleText(e, 'user_name')} 
                                required maxLength="10" />
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>이메일 주소</label><small className="text-navy"> (*필수입력사항)</small>
                        <div className="input-group">
                            <input type="text" id="email" className="form-control" placeholder="***@***.com"
                                value={this.props.userState.email} 
                                onChange={(e) => this.props.handleText(e, 'email')} 
                                required maxLength="255" />
                        </div>
                    </div> */}
                    <div className="form-group m-b-md">
                        <label>관리자 이름</label><small className="text-navy"></small>
                        <div className="input-group">
                        {
                            this.props.userState.contact_user_name != null
                            ?   <input type="text" id="contact_user_name" className="form-control"
                                value={this.props.userState.contact_user_name} 
                                onChange={(e) => this.props.handleText(e, 'contact_user_name')}
                                onBlur={this.handleManagerNameOnBlur}
                                required maxLength="10" />
                            :   <input type="text" id="manager_name" className="form-control"
                                value={this.props.userState.manager_name} 
                                onChange={(e) => this.props.handleText(e, 'manager_name')}
                                onBlur={this.handleManagerNameOnBlur}
                                required maxLength="10" />
                        }
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>관리자 이메일</label><small className="text-navy"></small>
                        <div className="input-group">
                            {
                                this.props.userState.contact_email != null
                                ?   <input type="text" id="contact_email" className="form-control" placeholder="***@***.com"
                                    value={this.props.userState.contact_email} 
                                    onChange={(e) => this.props.handleText(e, 'contact_email')}
                                    onBlur={this.handleManagerEmailOnBlur}
                                    required maxLength="255" />
                                :   <input type="text" id="manager_email" className="form-control" placeholder="***@***.com"
                                    value={this.props.userState.manager_email} 
                                    onChange={(e) => this.props.handleText(e, 'manager_email')}
                                    onBlur={this.handleManagerEmailOnBlur}
                                    required maxLength="255" />
                            }
                        </div>
                    </div>
                    <div className="form-group m-b-lg">
                        <label>관리자 연락처</label><small className="text-navy"></small>
                        <div className="input-group">
                        {
                            this.props.userState.contact_tel_no != null
                            ?   <input type="text" id="contact_tel_no" className="join-form form-control" placeholder="***-****-****"
                                value={this.props.userState.contact_tel_no} 
                                onChange={(e) => this.props.handleText(e, 'contact_tel_no')}
                                   onBlur={this.handleManagerTelNoOnBlur}
                                required maxLength="13" />
                            :  <input type="text" id="manager_tel_no" className="join-form form-control" placeholder="***-****-****"
                                value={this.props.userState.manager_tel_no} 
                                onChange={(e) => this.props.handleText(e, 'manager_tel_no')}
                                onBlur={this.handleManagerTelNoOnBlur}
                                required maxLength="13" />
                        }
                        </div>
                    </div>
                    {
                        this.props.userState.user_id.indexOf("_09001") < 0 &&
                        <div className="form-group m-b-md">
                            <label>주소</label>
                            <div className="input-group m-b-xs">
                                <input type="text" id="corp_addr" className="form-control" placeholder="기본 주소 (시/군/도)"
                                    value={this.props.userState.corp_addr} 
                                    onChange={(e) => this.props.handleText(e, 'corp_addr')}
                                    onBlur={this.handleCorpAddrOnBlur}
                                    required maxLength="50" />
                            </div>
                            <div className="input-group">
                                <input type="text" id="corp_addr2" className="form-control" placeholder="상세 주소" 
                                    value={this.props.userState.corp_addr2} 
                                    onChange={(e) => this.props.handleText(e, 'corp_addr2')} 
                                    required maxLength="50" />
                            </div>
                        </div>
                    }
                </>
            : ''
        )
    }
}

export default SignupForm_trend;